@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap');


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
    padding: 0.3rem;
    border: solid black 1px;
    margin-bottom: 1rem;
}

fieldset input {
    margin: 0.3rem;
}

.bg-about-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("images/about.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.old-font {
    font-family: "Playfair Display", serif;
}

* {
    font-family: "Noto Serif Display", serif;
    font-optical-sizing: auto;
}

.menu_appear_anim {
    animation: 0.25s 1 normal menu_item_appear;
    transition: 0.3s
}

.menu_appear_anim:hover {
    transform: translateX(10px);
}

@keyframes menu_item_appear {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

.filter-checkbox {
    accent-color: black;
}

fieldset {
    border-color: #e5e5e5;
    border-style: solid;
}

.slick-slide > div {
    margin: 0 5px;
}

.slick-prev {
    background: black !important;
    border-radius: 100%;
    content: '' !important;
    z-index: 1;
}

.slick-next {
    background: black !important;
    border-radius: 100%;
    content: '' !important;
    z-index: 1;
}

.h-full-custom {
  height: calc(var(--vh, 1vh) * 100);
}
